import * as React from "react"
import { useTheme } from '~/contexts/ThemeContext'
import { usePdp } from '~/contexts/PdpContext'
import { oneSize, jnstyls } from '~/utils/helpers'

import FitNotice from '~/components/pdp/fit-notice'

import { aria_btn } from "~/styles/app.module.scss"
import * as pdpstyles from "~/styles/pdp/pdp.module.scss"
import * as styles from "~/styles/pdp/sizes_alts.module.scss"


const Sizes = () => {
  const { setShowSizing, pdpVariant, isSHM } = useTheme()
  const { product, onVariantSelect, variants } = usePdp()

  const { is_gift, is_single, csoon, final_sale, sizing } = product

  const var_avail = (variant) => {
    if (is_gift) return ''
    if (csoon) return styles.preorder
    const wh_combo = (!!variant?.inv?.DW || !!variant?.inv['BL - Ecommerce'])
    const ship_avail = !isSHM ? !!variant.ship : wh_combo
    if ( variant.available && (ship_avail || variant?.pickup)) return ''
    return styles.unavailable 
  }

  const checkNotice = () => {
    if (is_gift || !pdpVariant) return false
    const vinv = pdpVariant?.inv
    if (!pdpVariant || !vinv) return false
    const wh_combo = vinv.DW || vinv['BL - Ecommerce']

    const ship_avail = !isSHM ? pdpVariant.ship : wh_combo

    return (!ship_avail && pdpVariant?.pickup)
  }

  const checkDot = (variant, index) => {
    if (is_gift) return false
    const wh_combo = !!variant.inv?.DW || !!variant.inv['BL - Ecommerce']
    const ship_avail = !isSHM ? variant.ship : wh_combo
    const pick_only = !ship_avail && variant.pickup
    if (!pick_only) return ''

    return styles.pdot
  }

  return (
    <div key={product.id}>
      <div className={jnstyls([pdpstyles.info, pdpstyles.size_info])} slct-size=''>
        <div className={pdpstyles.label} data-select-size="">
          {is_gift ? 'Amount:' : `Size:`}
          <div className={pdpstyles.label_error}>Select a size</div>
        </div>
        {(!is_gift && sizing) && (
          <div>
            <button 
              type="button"
              className={`${aria_btn} ${pdpstyles.info_link}`}
              aria-label="Open size/fit guide dialog"
              type="button"
              onClick={(e)=>setShowSizing(sizing)}
            >
              Size/Fit Guide
            </button>
          </div>
        )}
      </div>
      {checkNotice() && (
        <div className={styles.pickonly}>
          Heads up: Item is available for pick-up only
        </div>
      )}
      
      <FitNotice />

      <div 
        className={jnstyls([styles.qsizes, styles.pdp_sizes, (is_gift || variants?.length > 8) ? styles.many_variants : ''])} 
        role="menu" 
        aria-labelledby="ar_label-select_size"
        size-grid=''
      >
        {variants?.length > 0 && variants.map( (variant, index) => 
          <button
            type="button"
            key={variant.id}
            id={`size-${variant.title.toLowerCase().replace('$','')}`}
            className={jnstyls([
              'aria_btn', 
              styles.option, 
              var_avail(variant), 
              is_single ? styles.opt_single : '',
              checkDot(variant,index)
            ])} 
            vid={variant.id}
            aria-label={is_gift ? `Select ${variant.title} gift card` : variant.available ? `Select size ${variant.title}` : `Size ${variant.title} not available`}
            aria-current={pdpVariant?.id === variant.id ? 'true' : 'false'}
            role="menuitem" 
            onClick={(e) => onVariantSelect(variant.id)}
            av={var_avail(variant) ? undefined : ''}
            un={var_avail(variant, index) ? '' : undefined}
          >
            {is_gift ? variant.title.replace('.00', '') : oneSize(variant.title)}
          </button>
        )}
        {(variants?.length > 8 && variants.length % 5 > 0) && (
          <>
            {Array.from({ length: 5 - (variants.length % 5) }, (_, index) => (
              <div key={index} className={styles.optspc}>&nbsp;</div>
            ))}
          </>
        )}
      </div>
      {final_sale && (
        <div 
          className={styles.pdpSize_shipstore}
        >
          <div className={jnstyls([styles.pdpSize_msg, styles.pdpUnavailable_msg])}>
            Final sale, no returns.
          </div>
        </div>
      )}
    </div>
  )
}

export default Sizes




