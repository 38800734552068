import React, { useState, useRef } from "react"
import { useTheme } from '~/contexts/ThemeContext'
import { usePdp } from '~/contexts/PdpContext'
import { isStag, jnstyls } from '~/utils/helpers'

import * as pdp_styles from "~/styles/pdp/pdp.module.scss"
import * as styles from "~/styles/pdp/cta_bis.module.scss"

const Bis = ({ sticky=false }) => {
  const { pdpVariant } = useTheme()
  const { product } = usePdp()
  const { is_single, csoon } = product

  const [btnText, setBtnText] = useState(false)
  const [notifText, setNotifText] = useState(false)

  const emailRef = useRef(null)
  const newsRef = useRef(null)
  const ctaRef = useRef(null)

  const validate_email = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
  }

  const nbis = async ( e ) => {
    const dt = new Date(Date.now())
    const tz_offset = -dt.getTimezoneOffset() * 60

    const email = emailRef.current.value
    const news = newsRef.current.checked
    const id = product.shop_id
    const var_id = pdpVariant.id

    if ( !validate_email(email) ) {
      setNotifText('Please enter a valid email.')
      return
    }

    ctaRef.current.setAttribute('disabled','disabled')
    setBtnText('Sending...')
    setNotifText(false)

    const shop = isStag() ? 'buck-mason-staging.myshopify.com' : 'buck-mason-usa.myshopify.com'
    let url = `https://app.backinstock.org/stock_notification/create.json?shop=${shop}`
    url += `&notification[email]=${email}`
    url += `&notification[product_no]=${id}`
    url += `&notification[quantity_required]=1&notification[accepts_marketing]=${news}`
    url += `&notification[customer_utc_offset]=${tz_offset}`
    url += `&variant[variant_no]=${var_id}`

    const request = await fetch(url)

    const response = await request.json()

    if ( response.status === 'OK' ) {
      ctaRef.current.removeAttribute('disabled')
      setBtnText("Thank you — You'll be the first to know")
      emailRef.current.value = ''
      localStorage.setItem('bis-e',email)
      localStorage.setItem('bis-m',news)
      return
    }

    const errors = response.errors.base.join('<br/>')
    setBtnText(false)
    setNotifText(errors)
    ctaRef.current.removeAttribute('disabled')
  }

  return (      
    <div 
      id="pdp-cta-unavailable" 
      className={jnstyls([
        styles.cta_unavail, 
        csoon ? styles.cta_csoon : '', 
        (csoon && is_single) ? styles.cta_csoon_novar : '',
        sticky ? styles.stck_cta : ''
      ])}
      bis=''
    >

      <div className={jnstyls([pdp_styles.info, styles.info])}>
        <label htmlFor="bis-email" className={pdp_styles.label}>
          {csoon ? (
            <>
              {pdpVariant ? `Get notified when it's available:` : `Select size to be notified when it's available:`}
            </>
          ) : 'Get back in stock notification:'}
        </label>
      </div>
      <input
        type="email"
        id="bis-email"
        className={styles.email}
        name="bis-email" 
        autoCapitalize="none"
        autoCorrect="off" 
        spellCheck="false" 
        autoComplete="email" 
        autocompletetype="email" 
        x-autocompletetype="email" 
        placeholder="Your email" 
        ref={emailRef}
      />
      
      <div className={styles.news_opt}>
        <label className="squarecheck_label" htmlFor="bis-news" >
          <input 
            type="checkbox" 
            id="bis-news" 
            aria-labelledby="ar_label-bis" 
            ref={newsRef}
          />
          <div className="squarecheck_check"></div>
          <span id="ar_label-bis">
            Also send updates on new product releases?
          </span>
        </label>
      </div>

      <button 
        type="button" 
        className={styles.cta_btn}
        ref={ctaRef}
        onClick={(e) => nbis(e) }
        bis-btn=''
      >
        {btnText ? (
          btnText
        ) : csoon ? (
          'Join the Waitlist'
        ) : (
          'Notify Me When Available'
        )}
      </button>

      {notifText && (
        <div className={styles.bis_notif}>
          <div className={styles.bis_text}>
            {notifText}
          </div>
        </div>
      )}

    </div>
  )
}

export default Bis


